import React from "react";

import { ThemeProvider } from "@emotion/react";

import HomeScreen from "./screens/HomeScreen";
import ProductsScreen from "./screens/ProductsScreen";

import theme from "./theme";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <HomeScreen />
      <ProductsScreen />
    </ThemeProvider>
  );
};

export default App;
