import React, { useState } from "react";

import {
  Typography,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Link,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

import Logo from "../assets/images/Logo.png";
import { WhatsApp } from "@mui/icons-material";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="bg-gray-100 p-3 shadow-lg">
      <Grid
        container
        spacing={2}
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item zeroMinWidth>
          <img className="w-40" src={Logo} alt="logo" />
        </Grid>
        <Grid item>
          {isMobile && (
            <IconButton onClick={handleClick} aria-label="menu" size="large">
              <MenuIcon className="text-white" />
            </IconButton>
          )}
          {!isMobile && (
            <div className="flex-row">
              <Link href="#servicos" className="pr-12">
                Serviços
              </Link>
              <Link className="pr-8" href="#contato">
                Contato
              </Link>
              <Button
                variant="contained"
                color="success"
                startIcon={<WhatsApp />}
                href="https://api.whatsapp.com/send?phone=5548991122887"
              >
                WhatsApp
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <Link href="#servicos">Serviços</Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link href="#contato">Contato</Link>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Navbar;
