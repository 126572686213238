import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    success: {
      main: "#25D366",
      contrastText: "white",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: "white",
          textDecoration: "none",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#1e1e1e",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            color: "white",
            borderColor: "white",
          },
        },
      ],
      styleOverrides: {
        root: {
          color: "#fff",
          textTransform: "none",
          borderRadius: "25px",
          "&:hover": {
            borderColor: "#fff",
          },
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { size: "medium" },
          style: {
            borderColor: "#fff",
            borderWidth: "1px",
            borderRadius: "25px",
            borderStyle: "solid",
            boxSizing: "border-box",
          },
        },
      ],
    },
  },
});

export default theme;
