import React, { useEffect } from "react";
import Wave from "react-wavify";

import {
  Button,
  Container,
  Grid,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Navbar from "../components/Navbar";

import Logo from "../assets/images/Logo.png";

const images: any[] = [
  {
    image: require("../assets/images/img1.jpg"),
  },
  {
    image: require("../assets/images/img2.jpg"),
  },
  {
    image: require("../assets/images/img4.jpg"),
  },
  {
    image: require("../assets/images/img5.jpg"),
  },
  {
    image: require("../assets/images/img6.jpg"),
  },
  {
    image: require("../assets/images/img7.jpg"),
  },
  {
    image: require("../assets/images/img8.jpg"),
  },
  {
    image: require("../assets/images/img9.jpg"),
  },
  {
    image: require("../assets/images/img10.jpg"),
  },
  {
    image: require("../assets/images/img11.jpg"),
  },
];

const styles = {
  background: {
    backgroundImage: `url(${images[0].image})`,
    backgroundPosition: "center",
    transition: "all 0.8s",
  },
};

const HomeScreen = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const maxSteps = images.length - 1;

  useEffect(() => {
    var body = document.getElementById("home")!;
    var id = 0;

    setInterval(() => {
      body.style.backgroundImage = `url(${images[id].image})`;
      id = id === maxSteps ? (id = 0) : (id = id + 1);
    }, 8000);
  }, [maxSteps]);

  return (
    <div id="home" style={styles.background}>
      <Grid
        className="flex h-screen bg-black bg-opacity-70"
        direction="column"
        justifyContent="space-between"
      >
        <Grid item>
          <Navbar />
        </Grid>
        <Grid item alignSelf="center" className="pt-20">
          <Container maxWidth="xs">
            <Grid container direction="column" spacing={2} className="p-3">
              <Grid item>
                <img className="w-96" src={Logo} alt="logo" />
              </Grid>
              <Grid item>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item xs={12}>
                    <Typography
                      variant="inherit"
                      className="text-white pt-8 pb-5 text-center"
                    >
                      Segurança e qualidade em cada serviço.
                    </Typography>
                  </Grid>
                  {isMobile && (
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="success"
                        size="large"
                        startIcon={<WhatsAppIcon />}
                        className="w-full"
                        href="https://api.whatsapp.com/send?phone=5548991122887"
                      >
                        Entrar em contato
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      size="large"
                      startIcon={<KeyboardArrowDownIcon />}
                      className="w-full"
                      href="#servicos"
                    >
                      Mais informações
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item>
          <Wave
            fill="#1e1e1e"
            options={{
              height: 75,
              amplitude: 20,
              speed: 0.15,
              points: 6,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default HomeScreen;
