import React from "react";

import {
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import BoltIcon from "@mui/icons-material/Bolt";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import ShowerIcon from "@mui/icons-material/Shower";
import HandymanIcon from "@mui/icons-material/Handyman";
import BuildIcon from "@mui/icons-material/Build";
import ContactScreen from "./ContactScreen";

const eletricServices = [
  {
    text: "Instalação residencial e predial.",
    icon: <MapsHomeWorkIcon fontSize="large" />,
  },
  {
    text: "Padrão de entrada.",
    icon: <BoltIcon fontSize="large" />,
  },
  {
    text: "Quadro de distribuição.",
    icon: <ElectricMeterIcon fontSize="large" />,
  },
  {
    text: "Execução de projeto luminotécnico.",
    icon: <LightbulbIcon fontSize="large" />,
  },
  {
    text: "Instalação de chuveiros, ventiladores, torneiras elétricas, etc.",
    icon: <ShowerIcon fontSize="large" />,
  },
  {
    text: "Manutenção de rede elétrica em geral.",
    icon: <HandymanIcon fontSize="large" />,
  },
];

const hidraulicServices = [
  {
    text: "Instalação e manutenção.",
    icon: <HandymanIcon fontSize="large" />,
  },
  {
    text: "Montagem de louças e metais.",
    icon: <BuildIcon fontSize="large" />,
  },
];

const ProductsScreen = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div id="servicos" className="h-screen bg-gray-100">
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className="md:pr-12">
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              className="h-screen"
            >
              <Grid item xs={12} md={12}>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  className={`text-white pt-5 ${isMobile && "text-center"}`}
                >
                  Serviços Elétricos
                </Typography>
              </Grid>
              {eletricServices.map(({ text, icon }: any) => {
                return (
                  <Grid item xs={12} md={12}>
                    <div className="flex flex-row items-center justify-start text-yellow-100">
                      {icon}
                      <Typography variant="h5" className="text-yellow-100 pl-3">
                        {text}
                      </Typography>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className="md:pl-12">
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={12} md={12}>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  className={`text-white pt-5 ${isMobile && "text-center"}`}
                >
                  Serviços Hidráulicos
                </Typography>
              </Grid>
              {hidraulicServices.map(({ text, icon }: any) => {
                return (
                  <Grid item xs={12} md={12}>
                    <div className="flex flex-row items-center py-5 justify-start text-yellow-100">
                      {icon}
                      <Typography variant="h5" className="text-yellow-100 pl-3">
                        {text}
                      </Typography>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <ContactScreen />
    </div>
  );
};

export default ProductsScreen;
