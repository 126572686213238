import React from "react";

import {
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Email, Instagram, Phone, WhatsApp } from "@mui/icons-material";

const ContactScreen = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div id="contato" className="h-screen bg-gray-100 mt-24">
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          className="min-h-screen py-5"
          justifyContent="space-between"
        >
          <Grid item xs={12}>
            <Typography
              variant={`${isMobile ? "h3" : "h2"}`}
              className="text-yellow-100"
            >
              Solicite um orçamento.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {!isMobile && (
                <Grid alignSelf="center" item xs={12} md={10}>
                  <Grid
                    container
                    spacing={2}
                    className={`${isMobile && "mb-10"}`}
                  >
                    <Grid item xs={12} md={6}>
                      <Typography className="text-white" variant="h5">
                        Redes sociais
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className="text-white" variant="h5">
                        Contato
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        size="large"
                        startIcon={<WhatsApp />}
                        variant="text"
                        className="opacity-80"
                        href="https://api.whatsapp.com/send?phone=5548991122887"
                      >
                        WhatsApp
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        size="large"
                        startIcon={<Phone />}
                        variant="text"
                        className="opacity-80"
                        href="tel:+5548991122887"
                      >
                        Telefone: +55 (48) 9 9112-2887
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        size="large"
                        startIcon={<Instagram />}
                        variant="text"
                        className="opacity-80"
                        href="https://instagram.com/alesandro_eletricista"
                      >
                        Instagram
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        size="large"
                        startIcon={<Email />}
                        variant="text"
                        className="opacity-80"
                        href="mailto:alesandroeletricista27@gmail.com?subject=Alesandro - Elétrica e Hidráulica"
                      >
                        E-mail: alesandroeletricista27@gmail.com
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {isMobile && (
                <Grid alignSelf="center" item xs={12} md={10}>
                  <Grid
                    container
                    spacing={2}
                    className={`${isMobile && "mb-10"}`}
                  >
                    <Grid item xs={12} md={6}>
                      <Typography className="text-white" variant="h5">
                        Redes sociais
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        size="large"
                        startIcon={<WhatsApp />}
                        variant="text"
                        className="opacity-80"
                        href="https://api.whatsapp.com/send?phone=5548991122887"
                      >
                        WhatsApp
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        size="large"
                        startIcon={<Instagram />}
                        variant="text"
                        className="opacity-80"
                        href="https://instagram.com/alesandro_eletricista"
                      >
                        Instagram
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className="text-white pt-8" variant="h5">
                        Contato
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        size="large"
                        startIcon={<Phone />}
                        variant="text"
                        className="opacity-80"
                        href="tel:+5548991122887"
                      >
                        Telefone: +55 (48) 9 9112-2887
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        size="large"
                        startIcon={<Email />}
                        variant="text"
                        className="opacity-80"
                        href="mailto:alesandroeletricista27@gmail.com?subject=Alesandro - Elétrica e Hidráulica"
                      >
                        E-mail: alesandroeletricista27@gmail.com
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} md={2} alignSelf="center">
                {isMobile ? (
                  <Button
                    variant="outlined"
                    size="large"
                    startIcon={<KeyboardArrowUpIcon />}
                    className="w-full"
                    href="#home"
                  >
                    Voltar ao topo
                  </Button>
                ) : (
                  <IconButton href="#home">
                    <KeyboardArrowUpIcon
                      className="text-white"
                      fontSize="large"
                    />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid alignSelf="end" item xs={12}>
            <div className="flex flex-row items-center justify-start">
              <LocationOnIcon fontSize="large" className="text-white" />
              <Typography
                variant={`${isMobile ? "inherit" : "h5"}`}
                className="text-white pl-3"
              >
                Florianópolis, Santa Catarina, Brasil
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ContactScreen;
